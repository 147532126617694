import React from 'react';
import { useTranslation } from 'react-i18next';
import QueryProvider from './services/react-query';
import { ConfigProvider } from "antd";
import RoutesWrapper from './routes';
import "dayjs/locale/ar";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import dayjs from 'dayjs';
import arEG from "antd/locale/ar_EG";
// import arEG from "antd/locale";

const App = () => {
  const { i18n } = useTranslation();
  const currentLang = i18n.language;

  dayjs.extend(customParseFormat);
  dayjs.extend(localizedFormat);
  dayjs.locale("ar");

  console.log = () => { };
  console.error = () => { };
  console.warn = () => { };

  return (
    <QueryProvider>
      <ConfigProvider
        direction={currentLang === "ar" ? "rtl" : "ltr"}
        locale={currentLang === "ar" ? arEG: ""}
      >
        <RoutesWrapper />
      </ConfigProvider>
    </QueryProvider>
  );
};

export default App;
