import React from "react";
import { Card, Form, Button, Input, Flex, Typography } from "antd";
import { Link, useNavigate, Navigate } from "react-router-dom";
import { useTranslation, withTranslation } from 'react-i18next';
import cogoToast from 'cogo-toast';
import { useMutation } from "@tanstack/react-query";
import { authRegister } from "../../network/auth";
import PhoneInputWithCountrySelect, {
  isValidPhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import 'react-phone-number-input/style.css'

const Register = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { mutate: onRegister, isPending } = useMutation({
    mutationFn: (formData) => authRegister(formData),
    onSuccess: (data) => {
      if (data?.false !== false) {
        cogoToast.success(t("auth.registerSuccess"), { position: "top-right" });
        navigate("/login");
      } else {
        cogoToast.warn(t("auth.incorrectData"), { position: "top-right" });
      }
    },
    onError: () => {
      cogoToast.warn(t("Something went wrong"), { position: "top-right" });
    }
  });

  const onFinish = (values) => {
    if (values.phone) {
      const phoneNumber = parsePhoneNumber(values.phone);
      if (phoneNumber) {
        values.phone_country_code = phoneNumber.country;
        values.phone = phoneNumber.nationalNumber;
      }
    }

    onRegister(values);
  };


  if (localStorage.getItem("token")) {
    return <Navigate to="/login" />;
  }

  return (
    <div>
      <Typography.Title
        level={4}
        className="text-center"
      >
        {t("auth.signUpTitle")}
      </Typography.Title>
      <Typography.Title
        level={5}
        type="secondary"
        className="text-center"
      >
        {t('auth.signUpSubTitle')}{" "}
        <Link to="/login">{t('auth.login')}</Link>{" "}
        {t('auth.here')}
      </Typography.Title>
      <Flex justify="center" className="my-10">
        <Card style={{ width: "600px" }}>
          <Form form={form} name="register" onFinish={onFinish} layout="vertical">
            <Form.Item
              name="name"
              label={t('auth.name')}
              required={false}
              rules={[
                { required: true, message: t('auth.nameValidationRequired'), whitespace: true },
              ]}
            >
              <Input className="h-[38px]" placeholder={t("auth.namePlaceholder")} />
            </Form.Item>
            <Form.Item
              name="email"
              label={t('auth.email')}
              required={false}
              hasFeedback
              rules={[
                { type: 'email', message: t('auth.emailValidation') },
                { required: true, message: t('auth.emailValidationRequired') }
              ]}
            >
              <Input className="h-[38px]" placeholder={t("auth.emailPlaceholder")} />
            </Form.Item>
            <Form.Item
              name={"phone"}
              label={t('auth.phone')}
              colon={false}
              required={false}
              hasFeedback
              rules={[
                { required: true, message: t('auth.phoneValidationRequired') },
                {
                  validator: (_, value) => {
                    if (value && !isValidPhoneNumber(value)) {
                      return Promise.reject(new Error(t('auth.phoneValidation')));
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <PhoneInputWithCountrySelect
                defaultCountry="EG"
                international
              />
            </Form.Item>
            <Form.Item
              name="password"
              label={t("auth.password")}
              required={false}
              rules={[
                { required: true, message: t("auth.passwordValidationRequired") },
                { min: 8, message: t("auth.passwordRequirements.minLength") },
                {
                  pattern: /[a-zA-Z]/,
                  message: t("auth.passwordRequirements.letter"),
                },
                {
                  pattern: /[0-9]/,
                  message: t("auth.passwordRequirements.number"),
                },
              ]}
              hasFeedback
            >
              <Input.Password
                className="h-[38px]"
                placeholder={t("auth.passwordPlaceholder")}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" loading={isPending} htmlType="submit" className="w-full mt-2 h-10">
                {t("auth.signUp")}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Flex>
    </div>

  );
}

export default withTranslation()(Register);
