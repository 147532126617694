import React, { useState } from "react";
import cogoToast from "cogo-toast";
import { useNavigate } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../services/react-query/queryKeys";
import { Modal, Input, Rate, Button, Tabs, Avatar, Form } from 'antd';
import { listReviews, storeReviews } from "../../network/product";
import useGetUserData from "../../hooks/useGetUserData";
import { formatDescription } from "../../utils/utils";

const TabsSection = ({ data, t, i18n }) => {
  const { user } = useGetUserData();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const [form] = Form.useForm();
  const isRTL = i18n.language === 'ar';

  const { mutate: onAddReview, isPending: isReviewPending } = useMutation({
    mutationFn: async ({ productId, reviewData }) => await storeReviews(productId, reviewData),
    onSuccess: async (data) => {
      if (data.status === "true") {
        cogoToast.success(t("messages.orderPlacedSuccessfully"), {
          position: "top-right",
        });
        form.resetFields();
        setIsReviewModalOpen(false);
        queryClient.invalidateQueries([queryKeys.listReviews]);
      } else {
        cogoToast.error(t("messages.errorOccurred"), {
          position: "top-right",
        });
        form.resetFields();
        setIsReviewModalOpen(false);
      }
    },
    onError: () => {
      cogoToast.error(t("messages.errorOccurred"), {
        position: "top-right",
      });
      form.resetFields();
      setIsReviewModalOpen(false);
    },
  });

  const onFinish = async (values) => {
    const productId = data.id;
    const reviewData = values;
    onAddReview({ productId, reviewData });
  };

  const { data: reviewsData } = useQuery({
    queryKey: [queryKeys.reviews, data?.id],
    queryFn: () => listReviews(data?.id),
    enabled: !!data?.id,
  });

  const reviewHandler = () => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsReviewModalOpen(true);
    } else {
      navigate('/login');
    }
  };

  const hasUserReviewed = reviewsData?.data?.some(review => review.user_id === user?.id);

  return (
    <div>
      <Tabs defaultActiveKey="1" size="large" >
        <Tabs.TabPane tab={t('details')} key="1">
          {formatDescription(data?.description)}
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('reviews')} key="2">
          <div className="mb-6">
            {!hasUserReviewed && (
              <Button
                type="primary"
                size="large"
                onClick={reviewHandler}
                className="bg-blue-500"
              >
                {t('writeAReview')}
              </Button>
            )}
          </div>
          <div className="space-y-6">
            {reviewsData?.data?.map((review, idx) => (
              <div key={idx} className="border-b pb-6">
                <div className="flex items-start gap-4">
                  <Avatar size={40}>{review.user.name}</Avatar>
                  <div className="flex-1">
                    <div className="flex items-center gap-2 mb-2">
                      <span className="font-semibold">{review.user.name}</span>
                      <span className="text-gray-500">•</span>
                      <span className="text-gray-500">{review.date}</span>
                    </div>
                    <Rate defaultValue={review.rate} disabled />
                    <h4 className="font-semibold mt-2">{review.title}</h4>
                    <p className="text-gray-600 mt-2">{review.comment}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={<div className={`${isRTL ? 'ml-6' : 'ml-0'}`}>{t('shipping')}</div>}
          key="3"
        >
          <div className="prose max-w-none">
            <h4>{t('shippingInformationTitle')}</h4>
            <ul>
              <li>{t('expressShipping')}</li>
              <li>{t('internationalShipping')}</li>
              <li>{t('orderProcessing')}</li>
            </ul>
          </div>
        </Tabs.TabPane>
      </Tabs>

      <Modal
        title={t("reviewModal.title")}
        open={isReviewModalOpen}
        onCancel={() => setIsReviewModalOpen(false)}
        footer={null}
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            name="rate"
            rules={[
              {
                required: true,
                message: t("reviewModal.ratePlaceholder"),
              },
            ]}
          >
            <Rate />
          </Form.Item>
          <Form.Item
            name="title"
            rules={[
              {
                required: true,
                message: t("reviewModal.titlePlaceholder"),
              },
            ]}
          >
            <Input placeholder={t("reviewModal.reviewTitle")} />
          </Form.Item>
          <Form.Item
            name="comment"
            rules={[
              {
                required: true,
                message: t("reviewModal.commentPlaceholder"),
              },
            ]}
          >
            <Input.TextArea rows={4} placeholder={t("reviewModal.comment")} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isReviewPending} className="bg-blue-500">
              {t("reviewModal.submitReview")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default withTranslation()(TabsSection);