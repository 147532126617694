import React from "react";
import { Card, Form, Button, Input, Flex, Typography } from "antd";
import { Link } from "react-router-dom";
import { authResetPassword } from "../../network/auth";
import cogoToast from "cogo-toast";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";

const ResetPassword = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const { mutate: onResetPassword, isPending } = useMutation({
    mutationFn: (formData) => authResetPassword(formData),
    onSuccess: (data) => {
      cogoToast.success(data.message);
      form.resetFields();
    },
    onError: () => {
      cogoToast.warn(t("auth.errorMessage"), { position: "top-right" });
    },
  });

  const onFinish = (values) => {
    onResetPassword(values);
  };

  return (
    <div>
      <Typography.Title level={4} className="text-center">
        {t("auth.resetPasswordTitle")}
      </Typography.Title>
      <Typography.Title level={5} type="secondary" className="text-center">
        {t("auth.alreadyMember")}{" "}
        <Link to="/register">{t("auth.login")}</Link>{" "}
        {t("auth.here")}
      </Typography.Title>
      <Flex justify="center" className="my-10">
        <Card style={{ width: "600px" }}>
          <Form
            required={false}
            form={form}
            name="resetPassword"
            onFinish={onFinish}
            layout="vertical"
          >
            <Form.Item
              required={false}
              name="password"
              label={t("auth.newPassword")}
              rules={[
                { required: true, message: t("auth.passwordValidationRequired") },
                { min: 8, message: t("auth.passwordRequirements.minLength") },
                {
                  pattern: /[a-zA-Z]/,
                  message: t("auth.passwordRequirements.letter"),
                },
                {
                  pattern: /[0-9]/,
                  message: t("auth.passwordRequirements.number"),
                },
              ]}
            >
              <Input.Password placeholder={t("auth.newPasswordPlaceholder")} />
            </Form.Item>
            <Form.Item
              required={false}
              name="confirm"
              dependencies={['password']}
              label={t("auth.reEnterNewPasswordPlaceholder")}
              rules={[
                { required: true, message: t("auth.reEnterNewPasswordValidationRequired") },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t("auth.notMatchPassword")));
                  },
                }),
              ]}
            >
              <Input.Password placeholder={t("auth.reEnterNewPasswordPlaceholder")} />
            </Form.Item>
            <Form.Item>
              <Button loading={isPending} type="primary" htmlType="submit" className="auth-button">
                {t("auth.resetPassword")}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Flex>
    </div>
  );
};

export default ResetPassword;
