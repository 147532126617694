import React, { useState } from "react";
import cogoToast from "cogo-toast";
import { useNavigate } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../services/react-query/queryKeys";
import { addCart } from "../../network/cart";
import { addToFavorite, checkFavorite } from "../../network/favourite";
import { Card, Rate, Button } from 'antd';
import { HeartFilled, ShoppingCartOutlined } from "@ant-design/icons";
import { rating } from "../../network/rate";
import { CreditCard, Minus, Plus } from "lucide-react";
import { listReviews } from "../../network/product";
import useGetUserData from "../../hooks/useGetUserData";
import ShareButton from "../Common/ShareButton";
import ImageGallery from "./ImageGallery";
import TabsSection from "./TabsSection";

const Product = ({ data, t, i18n }) => {
  const [quantity, setQuantity] = useState("");
  const { user } = useGetUserData();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const onRating = useMutation({
    mutationFn: ({ rate, product_id }) => rating(rate, product_id),
    onSuccess: (data) => {
      if (data.status === "true") {
        cogoToast.success(t('messages.rateSuccess'), { position: "top-right" });
        queryClient.invalidateQueries([queryKeys.listProducts, data?.id, user?.id]);
        window.location.reload();
      } else {
        cogoToast.error(t("messages.errorOccurred"), { position: "top-right" });
      }
    },
    onError: () => {
      cogoToast.error(t("messages.errorOccurred"), { position: "top-right" });
    },
  });

  const addRating = (rate) => {
    if (!localStorage.getItem("token")) {
      cogoToast.warn(t("messages.addItemsLoginRequired"), { position: "top-right" });
    } else {
      let product_id = data?.id;
      onRating.mutate({ rate, product_id });
    }
  };

  const { mutate: onAddCart, isPending: isCartPending } = useMutation({
    mutationFn: (formData) => addCart(formData),
    onSuccess: (data) => {
      if (data.status === "true") {
        cogoToast.success(t("messages.itemAddedSuccessfully"), {
          position: "top-right"
        });
        queryClient.invalidateQueries([queryKeys.cartList]);
      } else {
        cogoToast.warn(t("messages.warningOccurred"), {
          position: "top-right",
        });
      }
    },
    onError: () => {
      cogoToast.warn(t("messages.warningOccurred"), {
        position: "top-right",
      });
    },
  });

  const addToCart = () => {
    if (quantity.length === 0 || quantity === "0") {
      cogoToast.error(t("messages.selectQuantity"), { position: "top-right" });
    } else if (!localStorage.getItem("token")) {
      cogoToast.warn(t("messages.addItemsLoginRequired"), { position: "top-right" });
    } else {
      const formData = new FormData();
      formData.append("quantity", quantity);
      formData.append("item_id", data?.id);
      formData.append("user_id", user?.id);
      formData.append("is_product", 1);
      onAddCart(formData);
    }
  };

  const { mutate: onBuyNow, isPending: isBuyPending } = useMutation({
    mutationFn: (formData) => addCart(formData),
    onSuccess: (data) => {
      if (data.status === "true") {
        cogoToast.success(t("messages.purchaseSuccessful"), {
          position: "top-right"
        });
        queryClient.invalidateQueries([queryKeys.cartList]);
        navigate("/cart");
      } else {
        cogoToast.warn(t("messages.errorOccurred"), {
          position: "top-right"
        });
      }
    },
    onError: () => {
      cogoToast.warn(t("messages.errorOccurred"), {
        position: "top-right"
      });
    },
  });

  const buyNowHandler = () => {
    if (quantity.length === 0 || quantity === "0") {
      cogoToast.error(t("messages.selectQuantity"), { position: "top-right" });
    } else if (!localStorage.getItem("token")) {
      cogoToast.warn(t("messages.addItemsLoginRequired"), { position: "top-right" });
    } else {
      const formData = new FormData();
      formData.append("quantity", quantity);
      formData.append("item_id", data?.id);
      formData.append("user_id", user?.id);
      formData.append("is_product", 1);
      onBuyNow(formData);
    }
  };

  const { mutate: onAddToFavorite, isPending: isFavoritePending } = useMutation({
    mutationFn: ({ product_id }) => addToFavorite(product_id),
    onSuccess: (data) => {
      if (data.isFavorite === true) {
        cogoToast.success(t("messages.addedToFavorites"), { position: "top-right" });
        queryClient.invalidateQueries([queryKeys.favorite, data?.id, user?.id]);
      } else {
        cogoToast.success(t("messages.removedFromFavorites"), { position: "top-right" });
        queryClient.invalidateQueries([queryKeys.favorite, data?.id, user?.id]);
      }
    },
    onError: () => {
      cogoToast.error(t("messages.errorOccurred"), { position: "top-right" });
    },
  });

  const addToFavourite = () => {
    if (!localStorage.getItem("token")) {
      cogoToast.warn(t("messages.addItemsLoginRequired"), { position: "top-right" });
    } else {
      let product_id = data?.id;
      onAddToFavorite({ product_id });
    }
  };

  const { data: favoriteData } = useQuery({
    queryKey: [queryKeys.favorite, data?.id],
    queryFn: () => checkFavorite(data?.id),
    enabled: !!user?.id && !!data?.id,
  });

  const { data: reviewsData } = useQuery({
    queryKey: [queryKeys.reviews, data?.id],
    queryFn: () => listReviews(data?.id),
    enabled: !!data?.id,
  });

  const quantityOnChange = (event) => {
    setQuantity(event.target.value);
  };

  const addQuantity = () => {
    setQuantity((prevQuantity) => Number(prevQuantity) + 1);
  };

  const subtractQuantity = () => {
    setQuantity((prevQuantity) => (prevQuantity > 1 ? Number(prevQuantity) - 1 : prevQuantity));
  };

  return (
    <Card hoverable>
      <div className="w-full">
        <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <div >
              <ImageGallery
                items={data?.images}
                showPlayButton={false}
                showFullscreenButton={true}
                useBrowserFullscreen={true}
              />
            </div>
            <div className="space-y-4">
              <div className="flex justify-between items-start">
                <div>
                  <h1 className="text-3xl font-bold text-gray-900">
                    {data?.name}
                  </h1>
                  <div className="mt-4 flex items-center">
                    <Rate name="rate"
                      size={25}
                      defaultValue={data?.average_rating}
                      precision={0.5}
                      disabled
                      onChange={(newValue) => addRating(newValue)}
                    />
                    <span className="ml-2 text-gray-500">({reviewsData?.data?.length ?? 0} {t('reviews')})</span>
                  </div>
                </div>
                <button className="p-2">
                  <HeartFilled
                    className={`${favoriteData?.isFavorite ? 'text-red-500' : 'text-gray-300'}`}
                    style={{ fontSize: '30px' }}
                    onClick={addToFavourite}
                    isLoading={isFavoritePending}
                  />
                </button>
              </div>
              <div className="py-2">
                {data?.discount_price === null ?
                  <div className="flex items-baseline gap-4">
                    <span className="xl:text-3xl md:text-2xl lg:text-2xl text-1xl font-bold text-blue-600">
                      {data?.price}{t('currency')}
                    </span>
                  </div>
                  :
                  <div className="flex items-baseline gap-4">
                    <span className="xl:text-3xl md:text-2xl lg:text-2xl text-1xl font-bold text-blue-600">{data?.discount_price}{t('currency')}</span>
                    <span className="text-gray-500 line-through">{data?.price}{t('currency')}</span>
                    <span className="bg-red-100 text-red-800 text-sm font-medium px-2.5 py-0.5 rounded">
                      {Math.round(((data?.price - data?.discount_price) / data?.price) * 100)}% {t('discount')}
                    </span>
                  </div>
                }
              </div>
              <div className="space-y-5">
                <div className="flex items-center gap-4">
                  <div className="flex items-center border rounded-md">
                    <button
                      className="p-2 hover:bg-gray-100"
                      onClick={subtractQuantity}
                    >
                      <Minus className="w-5 h-5" />
                    </button>
                    <input
                      type="number"
                      value={quantity}
                      onChange={quantityOnChange}
                      placeholder="1"
                      min="1"
                      className="w-16 text-center border-x"
                    />
                    <button
                      className="p-2 hover:bg-gray-100"
                      onClick={addQuantity}
                    >
                      <Plus className="w-5 h-5" />
                    </button>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4 mt-4">
                  <Button
                    type="primary"
                    icon={<ShoppingCartOutlined />}
                    className="w-full xl:mt-0 lg:mt-0 md:mt-0 mt-2"
                    onClick={addToCart}
                    size="large"
                    loading={isCartPending}
                  >
                    {t('cart')}
                  </Button>
                  <Button
                    loading={isBuyPending}
                    icon={<CreditCard className="w-5 h-5" />}
                    onClick={buyNowHandler}
                    size="large"
                    style={{ background: "#dbeafe", color: "#1677ff" }}
                    className="hover:bg-blue-200 w-full xl:mt-0 lg:mt-0 md:mt-0 mt-2">
                    {t('buy')}
                  </Button>
                </div>
                <div className="flex items-center text-gray-500 hover:text-gray-700">
                  <ShareButton />
                </div>
              </div>

              <TabsSection data={data} t={t} i18n={i18n} />
              
            </div>
          </div>
        </main>
      </div>
    </Card>
  );
};

export default withTranslation()(Product);